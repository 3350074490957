import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
// import { Link } from 'react-router-dom';
import { compose } from 'fe-hoc-compose';
import { connect } from 'fe-hoc-connect';
import { fulfill } from 'fe-hoc-fulfill';
import { notNull } from 'fe-hoc-not-null';
import {
  store,
  getState,
  getApps,
  getAppById,
  // getRelatedAppsByAppId,
  // selectRelatedAppsByAppId,
  // selectRelatedAppsByCategoryId,
} from 'fe-appdir-data-app';
import { ViewTrackIds, ContactInfoTrackIds } from 'fe-appdir-lib-analytics';
import AppContactInfo from 'fe-appdir-comp-app-contact-info';
import { AppInfo } from 'fe-appdir-comp-app-info';
import AppSummary from 'fe-appdir-comp-appsummary';
import Carousel from 'fe-appdir-comp-carousel';
import theme from 'fe-appdir-lib-theme';
import { isExternalInstall, getButtonLabel } from 'fe-appdir-lib-utils';

const Wrapper = styled.div`
  position: relative;
  max-width: 940px;
  margin: 0 auto;
  font-family: 'Open Sans', sans-serif;

  @media (max-width: ${theme.breakpointMd}) {
    margin: 0 ${theme.spacingMd};
  }
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  min-height: 300px;
  background-image: linear-gradient(to right, #2cd5c4, #00c3d5, #00aeef);
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacingXl} 0 ${theme.spacingMd};
  padding-top: 24px;

  @media (min-width: ${theme.breakpointMd}) {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 ${theme.spacingMd};
    padding-top: 96px;
  }
`;

const H3 = styled.h3`
  margin-top: 32px;
  font-size: ${theme.fontSizeH3};
  font-weight: ${theme.fontWeightSemibold};

  @media (min-width: ${theme.breakpointMd}) {
    margin-top: 48px;
    font-size: ${theme.fontSizeH2};
  }
`;

const Ul = styled.ul`
  margin: 0;
  padding: 0 ${theme.spacingMd};
  font-size: ${theme.fontSizeMd};
`;

const Li = styled.li`
  margin: ${theme.spacingXl};
  margin-left: 0;
`;

const BackLinkWrapper = styled.div`
  transform: translateY(24px);

  @media (min-width: ${theme.breakpointMd}) {
    transform: translateY(64px);
  }
`;

const BackLink = styled.span`
  padding: 8px;
  font-size: 14px;
  color: #fff;
  transition: color 0.2s;

  &:hover {
    cursor: pointer;
    color: #f0f2f2;
  }
`;

const UserGuideLink = styled.a`
  display: block;
  margin: ${theme.spacingMd} 0;
  font-size: 16px;
  font-weight: 600;
  color: #00aeef;

  &:hover,
  &:focus {
    color: #1c8bc7;
  }
`;

const Arrow = styled.span`
  font-weight: 600;
`;

const getAvailablePlanTypes = (minPlanId) => {
  switch (minPlanId) {
    case 1:
      return 'Free, Professional, Team, Business, Enterprise';

    case 2:
      return 'Professional, Team, Business, Enterprise';

    case 3:
      return 'Business, Enterprise';

    // case 4 = Employee

    default:
      return null;
  }
};

const IFrameWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
`;

const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

const StatelessApp = ({ app, history }) => (
  <React.Fragment>
    <Helmet>
      <title>{app.name}</title>
      <meta
        name="description"
        content={app.hasOwnProperty('description') ? app.description : null}
      />
    </Helmet>
    <Background />
    <Wrapper>
      <BackLinkWrapper>
        <BackLink onClick={history.goBack}>
          <Arrow>&lt;</Arrow> Back
        </BackLink>
      </BackLinkWrapper>
      <TopWrapper>
        {app.hasOwnProperty('name') &&
        app.hasOwnProperty('slug') &&
        app.hasOwnProperty('iconUrl') &&
        app.hasOwnProperty('description') &&
        app.hasOwnProperty('provider') &&
        app.provider.hasOwnProperty('name') ? (
          <AppSummary
            name={app.name}
            graphic={() => (
              <img
                src={app.iconUrl}
                alt={app.description}
                width="64"
                height="64"
              />
            )}
            buttonLabel={getButtonLabel(app.installUrl)}
            developer={
              app.hasOwnProperty('creator') && app.creator.name
                ? app.creator.name
                : app.provider.companyName || app.provider.name
            }
            description={app.description}
            onClick={() => (window.location = app.installUrl)}
            gaTrackId={ViewTrackIds.INSTALL_APP}
            gaTrackInfo={app.slug}
          />
        ) : null}
        {app.hasOwnProperty('averageRating') &&
        app.hasOwnProperty('billing') &&
        app.billing.hasOwnProperty('prices') &&
        app.billing.prices.length > 0 &&
        app.billing.prices[0].hasOwnProperty('price') &&
        app.hasOwnProperty('categories') &&
        app.categories.length > 0 ? (
          <AppInfo
            isExternalInstall={isExternalInstall(app.installUrl)}
            price={`$${app.billing.prices[0].price}`}
            planTypes={getAvailablePlanTypes(app.minPlanId)}
            requirements={
              app.hasOwnProperty('requirements') ? app.requirements : null
            }
            categories={app.categories.map((category) => category.name)}
          />
        ) : null}
      </TopWrapper>
      {app.hasOwnProperty('mediaItems') && app.mediaItems.length > 0 ? (
        <React.Fragment>
          <H3>Videos and Screenshots</H3>
          <Carousel slidesToShow={1} slidesToScroll={1} sixteenByNine={true}>
            {app.mediaItems
              .filter(
                (mediaItem) =>
                  mediaItem.type !== 'ICON_26' && mediaItem.type !== 'ICON_256',
              )
              .map((mediaItem, index) =>
                mediaItem.type === 'VIDEO_OVERVIEW' ? (
                  <IFrameWrapper key={mediaItem.relativeOrder}>
                    <IFrame
                      title={app.name}
                      src={`https://www.youtube.com/embed/${mediaItem.url.replace(
                        'https://youtu.be/',
                        '',
                      )}?controls=0`}
                      gesture="media"
                      allow="encrypted-media"
                      allowfullscreen
                    />
                  </IFrameWrapper>
                ) : (
                  <img
                    key={mediaItem.relativeOrder}
                    src={mediaItem.url}
                    alt={`${app.name} ${mediaItem.type}`}
                  />
                ),
              )}
          </Carousel>
        </React.Fragment>
      ) : null}
      {app.hasOwnProperty('userGuideUrl') ? (
        <UserGuideLink href={app.userGuideUrl}>User Guide</UserGuideLink>
      ) : null}
      {app.hasOwnProperty('features') && app.features.length > 0 ? (
        <React.Fragment>
          <H3>Key Features</H3>
          <Ul>
            {app.features.map((feature, index) => (
              <Li key={index}>{feature.featureText}</Li>
            ))}
          </Ul>
        </React.Fragment>
      ) : null}
    </Wrapper>
    <AppContactInfo
      provider={
        app.hasOwnProperty('creator')
          ? { ...app.creator, websiteUrl: app.creator.url }
          : app.provider
      }
      partner={app.partner}
      gaTrackIds={ContactInfoTrackIds}
      gaTrackInfo={app.slug}
    />
  </React.Fragment>
);

const App = compose(
  fulfill(
    async ({ match }) => {
      await getApps({ slug: match.params.appSlug });
      const appId = getState().apps.idsBySlug[match.params.appSlug];
      await getAppById(appId);
    },
    (prevProps, nextProps) =>
      prevProps.match.params.appSlug !== nextProps.match.params.appSlug,
  ),

  connect(store, (state, { match }) => {
    const appId = state.apps.idsBySlug[match.params.appSlug];

    return {
      app: state.apps.byId[appId],
    };
  }),
  notNull(['app'], () => null),
)(StatelessApp);

export default App;
